import AddIcon from '@mui/icons-material/Add';
import { Box, IconButton, Tooltip } from '@mui/material';
import { GridColDef, GridRenderCellParams } from '@mui/x-data-grid';
import documentStatus from 'assets/constants/document-status';
import documentSubType from 'assets/constants/document-sub-type';
import documentTags from 'assets/constants/document-tags';
import documentType from 'assets/constants/document-type';
import fieldLabel from 'assets/constants/fieldLabel';
import eventBus from 'helpers/event-bus-helper';
import { getObjectKeyValue } from 'helpers/object-field-helper';
import { DocumentsListEntity } from 'types/document-types';
import { OptionType } from 'types/option-type';

const emailAttachmentColumns: GridColDef[] = [
  {
    headerName: '',
    field: 'action',
    sortable: false,
    flex: 0.6,
    minWidth: 50,
    maxWidth: 50,
    renderCell: ({ row }: GridRenderCellParams<DocumentsListEntity>) => (
      <>
        <IconButton
          color="primary"
          onClick={() => {
            eventBus.dispatch('ATTACH_DOCS', {
              id: row.id,
              document_name: row.document_name
            });
          }}
        >
          <AddIcon />
        </IconButton>
      </>
    )
  },
  {
    headerName: fieldLabel.documentName,
    field: 'document_name',
    sortable: true,
    flex: 1,
    minWidth: 350,
    renderCell: ({ row }: GridRenderCellParams<DocumentsListEntity>) => {
      const rowDocumentType =
        documentSubType[row.category_id]
          ?.map((x: OptionType) =>
            x.value === row.document_subtype ? x.label : ''
          )
          .filter(Boolean) || [];

      const subType =
        rowDocumentType.length > 0 ? rowDocumentType : row.document_subtype;

      return (
        <Box
          style={{
            whiteSpace: 'pre-wrap',
            wordBreak: 'break-word',
            overflowWrap: 'break-word'
          }}
        >
          <Tooltip title={row.pdf_template_name}>
            <span style={{ fontWeight: 'bold' }}>{row.document_name}</span>
          </Tooltip>
          <div style={{ marginTop: '4px', fontSize: '12px' }}>
            {`${getObjectKeyValue(documentType, row.category_id)}${
              subType ? ` • ${subType}` : ''
            }${
              row.document_tag
                ? ` • ${getObjectKeyValue(documentTags, row.document_tag)}`
                : ''
            }  • ${getObjectKeyValue(documentStatus, row.status_id)}`}
          </div>
        </Box>
      );
    }
  }
];

export default emailAttachmentColumns;
